<div class="row mx-0 bg-color" >
    
    <div class="col-12 px-0" >

            <div class="row mx-0 justify-content-center pt-1">
                <div class="col-6 px-0">
                    <img class="search-button rounded-left clickable" src="../../../assets/images/static/search-white-icon.svg" height="40px" alt="" (click)= "searchByUserIdData(userIdValue.value)">
                    <input #userIdValue class="input-box-field rounded-right" placeholder="User ID / Email / First name / Mobile number / Alias" (keyup.enter)= "searchByUserIdData(userIdValue.value)">
                </div>
            </div>

            <!-- colors type for user -->
            <div class="row mx-0">
                <div class="col-12 px-4">
                    <div class="opensans-regular small-text c-darkgray">
                        <span>
                            Get user based on:
                            <select class="input-field c-darkgray body-subtext account-detail-answer" [(ngModel)]="filterTypeValue" (change)="filterValue(filterTypeValue)">
                                <!-- <option value=null disabled>Select</option> -->
                                <option [value]="item" *ngFor="let item of filterBasedOn">{{item}}</option>
                            </select>
                        </span>

                        <span class="px-2">
                            Sort by:
                            <select class="input-field c-darkgray body-subtext account-detail-answer" [(ngModel)]="sortTypeValue" (change)="sortTypeFilter(sortTypeValue)">
                                <!-- <option value=null disabled>Select</option> -->
                                <option [value]="item" *ngFor="let item of sortByType">{{item}}</option>
                            </select>
                        </span>
                        <span class="px-2">
                            <button mat-button (click)="applyFilter()" [disabled] = "disableApplyFilterButton" style="background-color: #06d6a0; color:white">APPLY FILTER </button>
                        </span>
                        <span>
                            <mat-form-field appearance="fill" style="width: 260px;">
                                <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [max]="maxdate">
                                    <input  matStartDate formControlName="start" placeholder="Start date" (dateChange)="addEvent($event, 'startDate')">
                                    <input matEndDate formControlName="end" placeholder="End date"  (dateChange)="addEvent($event, 'endDate')">
                                </mat-date-range-input>
                                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                            </mat-form-field>
                            <span>
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>
                            </span>
                        </span>
                        
                        


                        <!-- <span class="c-darkgray body-subtext">
                            <input [hidden]="dateInput.value" (click)="dp.open()" [max]="maxdate"
                              [matDatepicker]="dp" placeholder="Select date" [formControl]="dateInput" (dateChange)="addEvent($event)">    
                        </span>

                        <mat-datepicker #dp touchUi="true" startView="multi-year"></mat-datepicker>

                        <span class="input-field account-detail-answer" *ngIf="dateInput.value" (click)="dp.open()">
                            <span class="body-subtext pt-1 c-darkgray">{{dateInput.value | date: 'longDate'}}</span>
                        </span> -->

                        <span>
                            <div class="float-right body-subtext c-darkgray pt-4">
                                <span class="color-property" style="background-color: #cacaca;"></span><span class="opensans-light pl-4 pr-2">In screening </span>
                                <span class="color-property" style="background-color: #9cd2ec;"></span><span class="opensans-light pl-4 pr-2">Incomplete profile </span>
                                <!-- <span class="color-property" style="background-color: #cb5248;"></span><span class="opensans-light pl-4 pr-2">Deletion request</span> -->
                                <span class="color-property" style="background-color: #cfb903;"></span><span class="opensans-light pl-4">Fake profile</span>
                            </div>
                        </span>
                    </div>
                </div>
            </div>

            <!-- After Search Result -->
            <table *ngIf="resultAfterSearch" class="table-size-after-searching mat-elevation-z5" >
                <thead class="div-sticky" *ngIf="apiresponceForSearch">
                    <tr class="oswald c-darkgray body-subtext text-center " style="background-color: #e1effb;">
                        <th>No.</th>
                        <th>Get complete profile</th>
                        <th>Edit/view profile</th>
                        <th>Moderate profile</th>
                        <th>Signup date</th>
                        <th>Node ID </th>
                        <th>Profile ID </th>
                        <th>Last saved question </th>
                        <th>Last online at</th>
                        <th>Email address</th>
                        <th><img src="../../../assets/images/static/shield-icon.svg" alt=""></th>
                        <th>Phone number</th>
                        <th><img src="../../../assets/images/static/shield-icon.svg" alt=""></th>
                        <th>User's first name</th>
                        <th>User's last name</th>
                        <th>Age</th>
                        <th>Location</th>
                        <th>Socio-econ status</th>
                        <th>Income</th>
                        <th>Is fake profile</th>
                        <th>No of photos(Approved)</th>
                        <th>No of matches generated</th>
                        <th>Unviewed matches</th>
                        <th>Like/Maybe Matches</th>
                        <th>Rejected matches</th>
                        <th>Last match generated on</th>
                        <th>Preferred Communication</th>
                        <th># user Like/maybe</th>
                        <th># user Reject</th> 
                        <th>remove from list</th> 
                    </tr>
                </thead>

                <tbody class="opensans-regular c-darkgray small-text text-center" *ngIf="apiresponceForSearch">
                    <tr class="clickable" (click)="highLightRow(i, item.nodeid)" id="r-class-{{i}}" *ngFor="let item of searchUserData; let i = index" [ngStyle]="{'background-color': changeColor[i] == item.nodeid  ? '#ff5f2c' : ((i+1)%2==0 ? '#f2f2f2' : '#ffffff'), 'color' : changeColor[i] == item.nodeid ? '#ffffff' : '#373737' }">
                        <td>{{i+1}}</td>
                        <td>
                            <button (click)="navigateToProfileViewAfterSearch('view',item.nodeid)" mat-button class="sm-btn mat-elevation-z5" style="background-color: #4caf50;">
                                <span class="opensans-regular body-subtext c-white">View profile</span>
                            </button>
                        </td>
                        <td>
                            <button (click)="navigateToProfileViewAfterSearch('edit',item.nodeid)" mat-button class="sm-btn mat-elevation-z5" style="background-color: #57bbdd;">
                                <span class="opensans-regular body-subtext c-white">Edit/view profile</span>
                            </button>
                        </td>
                        <td>
                            <button (click)="moderateProfile(item.nodeid)" mat-button class="sm-btn  mat-elevation-z5" style="background-color: #ff9800;">
                                <span class="opensans-regular body-subtext c-white">Moderate profile</span>
                            </button>
                        </td>
                        <td>{{item.signuptime | date: 'mediumDate' }}<br>{{item.signuptime | date: 'mediumTime' }}</td>
                        <td [ngClass]="changeColor[i] == item.nodeid ? 'row-color-class' : 'nodeid-color'">
                            <span class="clickable" (click)="navigateToProfileView(item.nodeid)">
                                <u>{{item.nodeid}}</u>
                            </span>
                        </td>
                        <td [ngClass]="changeColor[i] == item.nodeid ? 'row-color-class' : 'nodeid-color' ">
                            <span class="clickable" (click)="navigateToProfileView(item.nodeid)">
                                <u>{{item.alias}}</u>
                            </span>
                        </td>
                        <td>{{item.currentquestionnumber}}</td>
                        <td>{{item.accesstime | date: 'mediumDate'}}<br>{{item.accesstime | date: 'mediumTime' }}</td>
                        <td>{{item.email}}</td>
                        <td>
                            <img *ngIf="item.isemailverified" src="../../../assets/images/static/verify-icon.svg" alt="">
                            <img *ngIf="!item.isemailverified" src="../../../assets/images/static/unverify-icon.svg" alt="">
                        </td>
                        <td>{{item.phonenumber}}</td>
                        <td>
                            <img *ngIf="item.isphoneverified" src="../../../assets/images/static/verify-icon.svg" alt="">
                            <img *ngIf="!item.isphoneverified" src="../../../assets/images/static/unverify-icon.svg" alt="">
                        </td>
                        <td>{{item.firstname}}</td>
                        <td>{{item.lastname}}</td>
                        <td>{{item.dob}}</td> 
                        <td>{{item.location}}</td>
                        <td >
                            <span *ngIf="item.socioEcoStatussplit">
                                {{item.socioEcoStatussplit[0]}}<br>
                                <span *ngIf="item.socioEcoStatussplit[1]">({{item.socioEcoStatussplit[1]}}</span>
                            </span>
                            <span *ngIf="!item.socioEcoStatussplit"></span>
                        </td>
                        <td>
                            <span *ngIf="item.incomesplit">
                                {{item.incomesplit[0]}}<br>
                                <span *ngIf="item.incomesplit[1]">{{item.incomesplit[1]}}</span>
                            </span>
                            <span *ngIf="!item.incomesplit"></span>
                        </td>
                        <td>{{item.isfakeprofile}}</td>
                        <td>{{item.numberOfPhotosApproved}}</td>
                        <td>{{item.numberofmatches}}</td>
                        <td>{{item.unviewedmatches}}</td>
                        <td>{{item.acceptedmatches}}</td>
                        <td>{{item.rejectedmatches}}</td>
                        <td>{{item.latestmatchdate}}</td>
                        <td>
                            <span *ngFor="let row of item.preferredcommunication; let last=last">{{row}}<i *ngIf="!last">,</i></span>
                        </td>
                        <td>{{item.userwhoshortlisted}}</td>
                        <td>{{item.userwhoblocked}}</td>
                        <td>
                            <button (click)="removeProfileFromList(item.nodeid)" mat-button class="sm-btn mat-elevation-z5" style="background-color: #4caf50;">
                                <span class="opensans-regular body-subtext c-white">Remove</span>
                            </button>
                        </td>
                    </tr>
                </tbody>

                <div class="row mx-0 justify-content-center" *ngIf="!apiresponceForSearch" >
                    <div class="col-12 px-0 mat-elevation-z5" style="height:40px;background-color: #ced4da;"></div>
                    <div class="col-12 px-0 mat-elevation-z5 skelton-class" style="height: 35px;"></div>
                </div>
                
            </table>

           
            <div class="position-absolute ml-3 scrollLeftClass" (mouseenter)="scrollTo('left', $event)" (mouseleave)="clearIntervalRepeater()">
            </div>

            <div class="position-absolute scrollRightClass" (mouseenter)="scrollTo('right', $event)" (mouseleave)="clearIntervalRepeater()">
            </div>

            
            <!-- Active user table data -->
            <table #widgetsContent *ngIf="!resultAfterSearch && !isRemoveButtonClick && activeUserData.length > 0"  [ngClass]="apiresponse  ? (activeUserData.length > 8 ? 'table-size mat-elevation-z5' : 'mat-elevation-z5') : ''">
                <thead class="div-sticky" *ngIf="apiresponse">
                    <tr class="oswald c-darkgray body-subtext text-center " style="background-color: #e1effb;">
                        <th>No. </th>
                        <th class="text-left">Plan </th>
                        <th style="padding-left:100px;">Signup from </th>       
                        <th class="text-center">
                            <div class="row mx-0 clickable">
                                <div class="col" style="width:130px;">  
                                    <span class="pr-1">Signup date</span>
                                    <img *ngIf="sortingSignupToggle" class="text-right clickable" (click)="sorting('desc','signupDate')" src="../../../assets/images/static/arrow-up-blue.svg" alt="" height="17px" width="17px">
                                    <img *ngIf="!sortingSignupToggle" class="text-right clickable" (click)="sorting('asce','signupDate')" src="../../../assets/images/static/arrow-down-blue.svg" alt="" height="17px" width="17px">
                                </div>
                            </div>
                        </th>
                        <th>Node ID </th>
                        <th>Profile ID </th>
                        <th>Last saved question </th>
                        <th>
                            <div class="row mx-0 clickable">
                                <div class="col " style="width:150px;">  
                                    <span class="pr-1">Completed on</span>
                                    <img *ngIf="sortingCompletedOnToggle" class="text-right clickable" (click)="sorting('desc','completedOn')" src="../../../assets/images/static/arrow-up-blue.svg" alt="" height="17px" width="17px">
                                    <img *ngIf="!sortingCompletedOnToggle" class="text-right clickable" (click)="sorting('asce','completedOn')" src="../../../assets/images/static/arrow-down-blue.svg" alt="" height="17px" width="17px">
                                </div>
                            </div>
                        </th>
                        <th>
                            <div class="row mx-0 clickable">
                                <div class="col " style="width:150px;">  
                                    <span class="pr-1">Screened on</span>
                                    <img *ngIf="sortingCompletedOnToggle" class="text-right clickable" (click)="sorting('desc','completedOn')" src="../../../assets/images/static/arrow-up-blue.svg" alt="" height="17px" width="17px">
                                    <img *ngIf="!sortingCompletedOnToggle" class="text-right clickable" (click)="sorting('asce','completedOn')" src="../../../assets/images/static/arrow-down-blue.svg" alt="" height="17px" width="17px">
                                </div>
                            </div>
                        </th>
                        <th>Created by</th>
                        <th>Last accessed from </th>
                        <th>
                            <div class="row mx-0 clickable">
                                <div class="col" style="width:150px;">  
                                    <span class="pr-1">Last online at</span>
                                    <img *ngIf="sortingLastOnAtToggle" class="text-right" (click)="sorting('desc','lastOnlineAt')" src="../../../assets/images/static/arrow-up-blue.svg" alt="" height="17px" width="17px">
                                    <img *ngIf="!sortingLastOnAtToggle" class="text-right" (click)="sorting('asce','lastOnlineAt')" src="../../../assets/images/static/arrow-down-blue.svg" alt="" height="17px" width="17px">
                                </div>
                            </div>
                        </th>
                        <th>Login count</th>
                        <th>Email address</th>
                        <th><img src="../../../assets/images/static/shield-icon.svg" alt=""></th>
                        <th>Country code</th>
                        <th>Phone number</th>
                        <th><img src="../../../assets/images/static/shield-icon.svg" alt=""></th>
                        <th>Call user</th>
                        <th>
                            <div class="row mx-0 clickable">
                                <div class="col" style="width:130px;">  
                                    <span class="pr-1">Profile quality</span>
                                    <img *ngIf="sortingProfileQualityToggle" class="text-right" (click)="sorting('desc','profileQuality')" src="../../../assets/images/static/arrow-up-blue.svg" alt="" height="17px" width="17px">
                                    <img *ngIf="!sortingProfileQualityToggle" class="text-right" (click)="sorting('asce','profileQuality')" src="../../../assets/images/static/arrow-down-blue.svg" alt="" height="17px" width="17px">
                                </div>
                            </div>
                        </th>
                        <!-- <th>
                            <div class="row mx-0 clickable">
                                <div class="col" style="width:110px;;">  
                                    <span class="pr-1">AC prospect</span>
                                    <img *ngIf="sortingACprospectToggle" class="text-right" (click)="sorting('desc','acProspect')" src="../../../assets/images/static/arrow-up-blue.svg" alt="" height="17px" width="17px">
                                    <img *ngIf="!sortingACprospectToggle" class="text-right" (click)="sorting('asce','acProspect')" src="../../../assets/images/static/arrow-down-blue.svg" alt="" height="17px" width="17px">
                                </div>
                            </div>
                        </th> -->
                        <!-- <th>
                            <div class="row mx-0 clickable">
                                <div class="col" style="width:110px;">  
                                    <span class="pr-1">Elite profile</span>
                                    <img *ngIf="sortingEliteToggle" class="text-right" (click)="sorting('desc','eliteProfile')" src="../../../assets/images/static/arrow-up-blue.svg" alt="" height="17px" width="17px">
                                    <img *ngIf="!sortingEliteToggle" class="text-right" (click)="sorting('asce','eliteProfile')" src="../../../assets/images/static/arrow-down-blue.svg" alt="" height="17px" width="17px">
                                </div>
                               
                            </div>
                        </th> -->
                        <th>
                            <div class="row mx-0 clickable">
                                <div class="col" style="width:170px;">  
                                    <span class="pr-1">Upgrade probablity</span>
                                    <img *ngIf="sortingUpgradeToggle" class="text-right" (click)="sorting('desc','upgradeProbability')" src="../../../assets/images/static/arrow-up-blue.svg" alt="" height="17px" width="17px">
                                    <img *ngIf="!sortingUpgradeToggle" class="text-right" (click)="sorting('asce','upgradeProbability')" src="../../../assets/images/static/arrow-down-blue.svg" alt="" height="17px" width="17px">
                                </div>
                            </div>
                        </th>
                        <th>User's first name</th>
                        <th>User's last name</th>
                        <th>Prospect's name</th>
                        <th>
                            <div class="row mx-0 clickable">
                                <div class="col" style="width:70px;">  
                                    <span class="pr-1">Sex</span>
                                    <img *ngIf="sortingAgeToggle" class="text-right" (click)="sorting('desc','sex')" src="../../../assets/images/static/arrow-up-blue.svg" alt="" height="17px" width="17px">
                                    <img *ngIf="!sortingAgeToggle" class="text-right" (click)="sorting('asce','sex')" src="../../../assets/images/static/arrow-down-blue.svg" alt="" height="17px" width="17px">
                                </div>
                            </div>
                        </th>
                        <th>Age</th>
                        <th>Location</th>
                        <th>Marital status</th>
                        <th>Relationship goal</th>
                        <th>Time frame</th>
                        <th>Edu level</th>
                        <th>Socio-econ status</th>
                        <th>Income</th>
                        <th>
                            <div class="row mx-0 clickable">
                                <div class="d-flex">  
                                    <img class="px-2" src="../../../assets/images/static/unviewed-matches-black-icon.svg" alt="">
                                    <img *ngIf="sortingUnviewedMatchesToggle" (click)="sorting('desc','unViewedMatch')" class="text-right" src="../../../assets/images/static/arrow-up-blue.svg" alt="" height="17px" width="17px">
                                    <img *ngIf="!sortingUnviewedMatchesToggle" (click)="sorting('asce','unViewedMatch')" class="text-right" src="../../../assets/images/static/arrow-down-blue.svg" alt="" height="17px" width="17px">
                                </div>
                            </div>
                        </th>
                        <th>
                            <div class="row mx-0 clickable">
                                <div class="d-flex"> 
                                   <img class="px-2" src="../../../assets/images/static/viewed-matches-black-icon.svg" alt=""> 
                                   <img *ngIf="sortingViewedMatchesToggle" (click)="sorting('desc','viewedMatch')" class="" src="../../../assets/images/static/arrow-up-blue.svg" alt="" height="17px" width="17px">
                                   <img *ngIf="!sortingViewedMatchesToggle" (click)="sorting('asce','viewedMatch')" class="" src="../../../assets/images/static/arrow-down-blue.svg" alt="" height="17px" width="17px">
                                </div>
                            </div>
                        </th>
                        <th>
                            <div class="row mx-0 clickable">
                                <div class="d-flex" >  
                                    <img class="px-2" src="../../../assets/images/static/shortlist-black-icon.svg" alt="">
                                    <img *ngIf="sortingShortListMatchesToggle" (click)="sorting('desc','shortListMatch')" class="text-right" src="../../../assets/images/static/arrow-up-blue.svg" alt="" height="17px" width="17px">
                                    <img *ngIf="!sortingShortListMatchesToggle" (click)="sorting('asce','shortListMatch')" class="text-right" src="../../../assets/images/static/arrow-down-blue.svg" alt="" height="17px" width="17px">
                                </div>
                            </div>
                        </th>
                        <th>
                            <div class="row mx-0 clickable">
                                <div class="d-flex">  
                                   <img class="px-2" src="../../../assets/images/static/ignore-black-color-icon.svg" width="37px" alt="">
                                   <img *ngIf="sortingIgnoredMatchesToggle" (click)="sorting('desc','ignoredMatch')" class="text-right" src="../../../assets/images/static/arrow-up-blue.svg" alt="" height="17px" width="17px">
                                   <img *ngIf="!sortingIgnoredMatchesToggle" (click)="sorting('asce','ignoredMatch')" class="text-right" src="../../../assets/images/static/arrow-down-blue.svg" alt="" height="17px" width="17px">
                                </div>
                            </div>
                        </th>
                        <th>
                            <div class="row mx-0 clickable">
                                <div class="d-flex">  
                                    <img class="px-2" src="../../../assets/images/static/interest-sent-black-icon.svg" alt="">
                                    <img *ngIf="sortingInterestSentToggle" (click)="sorting('desc','interestSend')" class="text-right" src="../../../assets/images/static/arrow-up-blue.svg" alt="" height="17px" width="17px">
                                    <img *ngIf="!sortingInterestSentToggle" (click)="sorting('asce','interestSend')" class="text-right" src="../../../assets/images/static/arrow-down-blue.svg" alt="" height="17px" width="17px">
                                </div>
                            </div>
                        </th>
                        <th>
                            <div class="row mx-0 clickable">
                                <div class="d-flex">  
                                    <img class="px-2" src="../../../assets/images/static/mutual-interest-black-icon.svg" alt="">
                                    <img *ngIf="sortingMutualInterestToggle" (click)="sorting('desc','mutualInterest')" class="text-right" src="../../../assets/images/static/arrow-up-blue.svg" alt="" height="17px" width="17px">
                                    <img *ngIf="!sortingMutualInterestToggle" (click)="sorting('asce','mutualInterest')" class="text-right" src="../../../assets/images/static/arrow-down-blue.svg" alt="" height="17px" width="17px">
                                </div>
                            </div>
                        </th>
                        <th>
                            <div class="row mx-0 clickable">
                                <div class="d-flex">  
                                    <img class="px-2" src="../../../assets/images/static/interest-received-black-icon.svg" alt="">
                                    <img *ngIf="sortingInterestReceivedToggle" (click)="sorting('desc','interestReceived')" class="text-right" src="../../../assets/images/static/arrow-up-blue.svg" alt="" height="17px" width="17px">
                                    <img *ngIf="!sortingInterestReceivedToggle" (click)="sorting('asce','interestReceived')" class="text-right" src="../../../assets/images/static/arrow-down-blue.svg" alt="" height="17px" width="17px">
                                </div>
                            </div>
                        </th>
                        <th>
                            <div class="row mx-0 clickable">
                                <div class="d-flex">  
                                    
                                    <img class="px-2" src="../../../assets/images/static/interest-i-declined-black-icon.svg" alt="">
                                    <img *ngIf="sortingInterestIdeclineToggle" (click)="sorting('desc','interestIdeclined')" class="text-right" src="../../../assets/images/static/arrow-up-blue.svg" alt="" height="17px" width="17px">
                                    <img *ngIf="!sortingInterestIdeclineToggle" (click)="sorting('asce','interestIdeclined')" class="text-right" src="../../../assets/images/static/arrow-down-blue.svg" alt="" height="17px" width="17px">
                                </div>
                            </div>
                        </th>
                        <th>
                            <div class="row mx-0 clickable">
                                <div class="d-flex">  
                                    <img class="px-2" src="../../../assets/images/static/interest-they-declined-black-icon.svg" alt="">
                                    <img *ngIf="sortingInterestTeyDeclineToggle" (click)="sorting('desc','interestTheyDeclined')" class="text-right" src="../../../assets/images/static/arrow-up-blue.svg" alt="" height="17px" width="17px">
                                    <img *ngIf="!sortingInterestTeyDeclineToggle" (click)="sorting('asce','interestTheyDeclined')" class="text-right" src="../../../assets/images/static/arrow-down-blue.svg" alt="" height="17px" width="17px">
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
    
                <tbody class="opensans-regular c-darkgray small-text text-center" *ngIf="apiresponse">

                    <tr class="clickable" (click)="highLightRow(i, item.nodeId)" id="r-class-{{i}}" *ngFor="let item of activeUserData; let i = index" [ngStyle] ="{'background-color': changeColor[i] ? '#ff5f2c' : (item.isFakeProfileUser ? '#cfb903' : 
                    (item.issignuppending ? '#9cd2ec' : (item.profilecompletedon ? (item.isscreeningdone ? '#ffffff' : '#cacaca') : '' ))), 'color' : changeColor[i] ? '#ffffff' : '#373737'}">
                        <td>{{i+1}}</td>
                        <td>
                            <div class="row mx-0">
                                <div class="col-6 px-0">
                                    <span *ngIf="item.planExpireDate">
                                        <span *ngIf="item.planType == 'express'">
                                            <span *ngIf="item.planExpireDate >= currentDate">
                                                <img src="../../../assets/images/static/express-connect-blue-icon.svg" height="35px" width="35px" alt="">
                                            </span>
                                            <span *ngIf="item.planExpireDate < currentDate">
                                                <img src="../../../assets/images/static/express-connect-grey-icon.svg" height="35px" width="35px" alt="">
                                            </span>
                                        </span>
                                        <span *ngIf="item.planType == 'assisted'">
                                            <span *ngIf="item.planExpireDate >= currentDate">
                                                <img src="../../../assets/images/static/assisted-connect-blue-icon.svg" height="35px" width="35px" alt="">
                                            </span>
                                            <span *ngIf="item.planExpireDate < currentDate">
                                                <img src="../../../assets/images/static/assisted-connect-grey-icon.svg" height="35px" width="35px" alt="">
                                            </span>
                                        </span>
                                        <span *ngIf="item.planType == 'quick'">
                                            <span *ngIf="item.planExpireDate >= currentDate">
                                                <img src="../../../assets/images/static/quick-connect-blue-icon.svg" height="35px" width="35px" alt="">
                                            </span>
                                            <span *ngIf="item.planExpireDate < currentDate">
                                                <img src="../../../assets/images/static/quick-connect-grey-icon.svg" height="35px" width="35px" alt="">
                                            </span>
                                        </span>
                                        <br>
                                        <span *ngIf="item.planExpireDate">{{item.planExpireDate | date: 'mediumDate' }}</span>
                                    </span>
                                </div>
                                <div class="col-6 px-0 pt-2 pl-4">
                                    <button (click)="moderateProfile(item.nodeId)" mat-button class="sm-btn bg-btn-primary mat-elevation-z5">
                                        <span class="opensans-regular body-subtext c-white">MODERATE</span>
                                    </button>
                                </div>
                            </div>
                        </td>
                        <td style="padding-left:100px;">
                            <span *ngIf="item.signupFrom == 1">Desktop</span>
                            <span *ngIf="item.signupFrom == 2">Android App</span>
                            <span *ngIf="item.signupFrom == 4">Mobile web</span>
                        </td>
                        <td>{{item.signupDate | date: 'mediumDate' }}{{item.signupDate  ? ', ' : ''}}{{item.signupDate | date: 'mediumTime' }}</td>
                        <td [ngClass]="changeColor[i] ? 'row-color-class' : 'nodeid-color'">
                            <span class="clickable" (click)="navigateToProfileView(item.nodeId)">
                                <u>{{item.nodeId}}</u>
                            </span>
                        </td>
                        <td [ngClass]="changeColor[i] ? 'row-color-class' : 'nodeid-color' ">
                            <span class="clickable" (click)="navigateToProfileView(item.nodeId)">
                                <u>{{item.profileId}}</u>
                            </span>
                        </td>
                        <td>{{item.lastSavedQuestion}}</td>
                        <td>{{item.completedOn | date: 'mediumDate'}}{{item.completedOn  ? ', ' : ''}}{{item.completedOn | date: 'mediumTime' }}</td>
                        <td>{{item.screeningtimestamp | date: 'mediumDate'}}{{item.screeningtimestamp ? ', ' : ''}}{{item.screeningtimestamp | date: 'mediumTime' }}</td>
                        <td>{{item.createdBy}}</td>
                        <!-- <td>{{item.managedBy}}</td> -->
                        <td>
                            <span *ngIf="item.lastAccessFrom == 1">Desktop</span>
                            <span *ngIf="item.lastAccessFrom == 2">Android App</span>
                            <span *ngIf="item.lastAccessFrom == 4">Mobile web</span>
                        </td>
                        <td>{{item.lastOnlineAt | date: 'mediumDate'}}{{item.lastOnlineAt ? ', ' : ''}}{{item.lastOnlineAt | date: 'mediumTime' }}</td>
                        <td >{{item.loginCount}}</td>
                        <td>{{item.emailAddress}}</td>
                        <td>
                            <img *ngIf="item.emailVerified" src="../../../assets/images/static/verify-icon.svg" alt="">
                            <img *ngIf="!item.emailVerified" src="../../../assets/images/static/unverify-icon.svg" alt="">
                        </td>
                        <td>{{item.countryCode}}</td>
                        <td>{{item.phoneNumber}}</td>
                        <td>
                            <img *ngIf="item.phoneVerified" src="../../../assets/images/static/verify-icon.svg" alt="">
                            <img *ngIf="!item.phoneVerified" src="../../../assets/images/static/unverify-icon.svg" alt="">
                        </td>
                        <td>
                            <button (click)="callToUser(item.nodeId)" mat-button class="sm-btn  mat-elevation-z5" style="background-color: #4caf50;">
                                <span class="opensans-light body-subtext c-white">Call</span>
                            </button>
                        </td>
                        <td>
                            <span>{{item.profileQuality > 0 ? item.profileQuality : ''}}</span>
                        </td>
                        <!-- <td>
                            <span *ngIf="item.acProspect == null || item.acProspect == '-1'"> </span>
                            <span *ngIf="item.acProspect == 'yes' || item.acProspect == '0'">yes</span>
                            <span *ngIf="item.acProspect == 'no' || item.acProspect == '1'">no</span>
                            <span *ngIf="item.acProspect == 'maybe' || item.acProspect == 'Maybe' || item.acProspect == '2'">maybe</span>
                        </td> -->
                        <!-- <td>
                            <span *ngIf="item.eliteProfile == null || item.eliteProfile == '-1'"> </span>
                            <span *ngIf="item.eliteProfile == '1'">yes</span>
                            <span *ngIf="item.eliteProfile == '2'">no</span>
                            <span *ngIf="item.eliteProfile == '3'">maybe</span>
                        </td> -->
                        <td>
                            <span *ngIf="item.upgradeProbability == '40'">
                                <img src="../../../assets/images/static/ugrade-probabilty-one.svg" alt="">    
                            </span>
                            <span *ngIf="item.upgradeProbability == '60'">
                                <img src="../../../assets/images/static/upgrade-probabilty-four.svg" alt="">   
                            </span>
                            <span *ngIf="item.upgradeProbability == '80'">
                                <img src="../../../assets/images/static/upgrade-probabilty-seven.svg" alt="">   
                            </span>
                            <span *ngIf="item.upgradeProbability == '100'">
                                <img src="../../../assets/images/static/upgrade-probabilty-ten.svg" alt="">   
                            </span>
                        </td>
                        <td>{{item.firstName}}</td>
                        <td>{{item.lastName}}</td>
                        <td>{{item.prospectFirstName}}</td>
                        <td>
                            <span *ngIf="item.sex === true" class="mr-3">
                                <!-- <img src="../../../assets/images/static/male-icon.svg" height="45px" alt=""> -->
                                M
                            </span>
                            <span *ngIf="item.sex === false" class="mr-3">
                               <!-- <img src="../../../assets/images/static/female-icon.svg" height="45px" alt=""> -->
                                F
                            </span>
                            <span *ngIf="item.sex === null" class="mr-3">
                                <!-- <img src="../../../assets/images/static/female-icon.svg" height="45px" alt="">
                                 F -->
                             </span>
                        </td>
                        <td>{{item.age}}</td>
                        <td>{{item.location}}</td>
                        <td>{{item.maritalStatus}}</td>
                        <td>{{item.relationshipGoal}}</td>
                        <td>{{item.timeFrame}}</td>
                        <td>{{item.eduLevel}}</td>
                        <td >
                            <span *ngIf="item.socioEcoStatussplit">
                                {{item.socioEcoStatussplit[0]}}
                            <!-- <span *ngIf="item.socioEcoStatussplit[1]">({{item.socioEcoStatussplit[1]}}</span> -->
                            </span>
                            <span *ngIf="!item.socioEcoStatussplit"></span>
                        </td>
                        
                        <td>
                            <span *ngIf="item.incomesplit && (item.incomesplit != 'No income')">
                                {{item.incomesplit[0]}}{{item.incomesplit[1][0]}}
                                <!-- <span *ngIf="item.incomesplit[1]">{{item.incomesplit[1]}}</span> -->
                            </span>
                            <span *ngIf="item.incomesplit === 'No income' ">
                                No income
                            </span>
                            <span *ngIf="!item.incomesplit"></span>
                        </td>
                        <td>{{item.unViewedMatch}}</td>
                        <td>{{item.viewedMatch}}</td>
                        <td>{{item.shortListMatch}}</td>
                        <td>{{item.ignoredMatch}}</td>
                        <td>{{item.interestSend}}</td>
                        <td>{{item.mutualInterest}}</td>
                        <td>{{item.interestReceived}}</td>
                        <td>{{item.interestIdeclined}}</td>
                        <td>{{item.interestTheyDeclined}}</td>
                        
                    </tr>
                </tbody>

                    
                
            </table>
       
            <div class="row mx-0 justify-content-center ph-item" *ngIf="!apiresponse" >
                <div class="col-12 px-0 mat-elevation-z5" style="height:40px;background-color: #ced4da;"></div>
                <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
            </div>
            
            <!-- load more -->
            <div class="row mx-0 pt-2 pb-3" *ngIf="!resultAfterSearch && !isRemoveButtonClick">
                <div class="col-12 px-0 text-center" *ngIf="!disableShowMoreButton">
                    <!-- <button *ngIf="ReadMoreButton" disabled mat-button (click)="loadMoreData()" style="background-color: #373737; color:white">SHOW MORE</button> -->
                    <button [ngClass]="disableShowMoreButton ? 'disableShowMoreBgColor' : 'showMoreButtonBgColor' " [disabled] = "disableShowMoreButton" mat-button (click)="loadMoreData()">SHOW MORE</button>
                </div>
            </div>

            <div class="row mx-0" *ngIf="notFoundUserId || (activeUserData.length == 0 && searchUserData.length == 0)"> 
                <div class="col-12 px-0 pt-5 text-center oswald c-darkgray" style="font-size: 20px;" >
                    No data found
                </div>
            </div>
            
    </div>
    
    
</div>

